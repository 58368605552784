/* Global */
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html,body {
    height:100%;
    padding:0;
    margin:0;
}

/* Remove underlines and blue color from links */
a {
	text-decoration: none;
	color: inherit;
}

/* Dialog component width overrides */
.bp_dialog_md_width .MuiDialog-container > div {
	min-width: 300px !important;
	max-width: 450px !important;
	width: 85% !important;
}
.bp_dialog_lg_width .MuiDialog-container > div {
	min-width: 300px !important;
	max-width: 750px !important;
	width: 85% !important;
}
.bp_dialog_xl_width .MuiDialog-container > div {
	min-width: 300px !important;
	max-width: 1200px !important;
	width: 85% !important;
}

/* Phone Inputs */
.react-tel-input .form-control {
	width: 100% !important;
}

/* .react-tel-input .form-control.invalid-number { */
	/* TODO - make dynamic */
	/* border: 1px solid #d32f2f; */
/* } */

/* Tables */
.bp_table_manage_cell {
	width: 40px !important;
}

/* Data Filters */
.bp_data_filter_input {
	min-width: 120px;
}

/* Horizontal Tabs */
.MuiTabs-scrollButtons.Mui-disabled {
	opacity: 0.3;
}

/* Vertical Tabs */
.bp_vertical_tabs button {
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 4px;
	padding-right: 18px;
	align-items: baseline !important;
	min-height: 36px;
	text-align: left;
}

/* Message Input */
.bp_message_input {
	padding-top: 8px;
	display: inline-block;
	width: 100%;
	width: calc(100% - 78px);
	vertical-align: top;
}

.bp_message_input .MuiFormControl-fullWidth .MuiFormControl-root {
	margin-top:0px;
}

.bp_message_send_button {
	padding-top: 8px;
	display: inline-block;
	width: 78px;
	text-align: right;
	vertical-align: top;
}

.bp_message_send_button Button {
	height: 56px;
}


/* ********************** */
/* SPIN ANIMATION */

	.bp_spin {
		-moz-animation: spin 2s infinite linear;
		-o-animation: spin 2s infinite linear;
		-webkit-animation: spin 2s infinite linear;
		animation: spin 2s infinite linear;
		display: inline-block;
	}

	/* use animate-spin on these if possible */
	.bp_button_with_loading_icon span svg {
		-moz-animation: spin 2s infinite linear;
		-o-animation: spin 2s infinite linear;
		-webkit-animation: spin 2s infinite linear;
		animation: spin 2s infinite linear;
		display: inline-block;
	}

	/* use animate-spin on these if possible */
	.bp_button_with_loading_icon_child svg {
		-moz-animation: spin 2s infinite linear;
		-o-animation: spin 2s infinite linear;
		-webkit-animation: spin 2s infinite linear;
		animation: spin 2s infinite linear;
		display: inline-block;
	}

	@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
	}
	@-webkit-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
	}
	@-o-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
	}
	@-ms-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
	}
	@keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
	}
/* END SPIN ANIMATION */
/* ********************** */

.bp_show_vertical_scroll {
	overflow-y: scroll;
}

.bp_show_horizontal_scroll {
	overflow-x: scroll;
}

/* .bp_show_horizontal_scroll::-webkit-scrollbar {
	display: block !important;
	height: 12px;
	background-color: #272b33
}

.bp_show_horizontal_scroll::-webkit-scrollbar-thumb {
	background-color: #22c6ff;
	border-radius: 10px;
	border-top: 2px solid #272b33;
	border-bottom: 2px solid #272b33;
	border-left: 2px solid #272b33;;
	border-right: 2px solid #272b33;;
}
*/

.bp_show_vertical_scroll::-webkit-scrollbar {
	display: block !important;
	height: 12px;
	/* background-color: rgba(0,0,0,0) */
	background-color: #272b33
}

.bp_show_vertical_scroll::-webkit-scrollbar-thumb {
	background-color: #22c6ff;
	border-radius: 10px;
	border-top: 2px solid #272b33;
	border-bottom: 2px solid #272b33;
	border-left: 2px solid #272b33;;
	border-right: 2px solid #272b33;;
	/* border: 2px solid rgba(0,0,0,0) */
}

.bp_thin_text_input input {
	padding: 6.5px;
}

.bp_thin_select_input div.MuiSelect-select {
	padding: 6.5px;
}

.bp_thin_autocomplete_input div.MuiAutocomplete-inputRoot {
	padding: 0px;
}

.bp_thin_autocomplete_input div.MuiAutocomplete-input {
	padding: 0px;
}

.bp_thin_autocomplete_input label.MuiFormLabel-root {
	top: -8px
}

.bp_thin_select_multiple_input label {
	margin-top: -10px;
}

.bp_thin_select_multiple_input label.MuiInputLabel-shrink  {
	margin-top: 0px;
}

/* Reszie */
.ResizeHandleOuter {
	flex: 0 0 1.5em;
	position: relative;
	outline: none;
	background-color: transparent;
}
.ResizeHandleOuter[data-resize-handle-active] .ResizeHandleInner {
	background-color: rgba(255, 255, 255, 0.2);
}
.ResizeHandleInner {
	position: absolute;
	top: 0.25em;
	bottom: 0.25em;
	left: 0.25em;
	right: 0.25em;
	border-radius: 0.25em;
	background-color: #121212;
	transition: background-color 0.2s linear;
}
.ResizeHandleIcon {
	width: 1em;
	height: 1em;
	position: absolute;
	left: calc(50% - 0.5rem);
	top: calc(50% - 0.5rem);
}

.bp_table_va_top td {
	vertical-align: top;
}

/* Global scrollbar styling */
/* ::-webkit-scrollbar {
	display: block !important;
	height: 8px;
	background-color: #191E21
  }

::-webkit-scrollbar-thumb {
	background-color: #22c6ff;
	border-radius: 10px;
	border-top: 2px solid #272b33;
	border-bottom: 2px solid #272b33;
	border-left: 2px solid #272b33;;
	border-right: 2px solid #272b33;;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #22c6ff;
}

::-webkit-scrollbar-track {
	background-color: #191E21;
	border: 1px solid #272B33;
} */

body {
	overflow-y: scroll
}

body::-webkit-scrollbar {
	width: 0px;
	background: transparent; /* make scrollbar transparent */
  }

.bp_grayscale {
	filter: grayscale(100%);
}
.bp_grayscale_50 {
	filter: grayscale(50%);
}
.bp_grayscale_80 {
	filter: grayscale(80%);
}

.bp_black_background {
	background-color: #1c1f25;
}